import React from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { PartialContainer } from 'components'
import { TrackDetails, useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import airbnb from './airbnb.png'
import airbnbWhite from './airbnb-white.png'
import meituan from './meituan.png'
import meituanWhite from './meituan-white.png'
import spacex from './spacex.png'
import spacexWhite from './spacex-white.png'
import discord from './discord.png'
import discordWhite from './discord-white.png'
import rubrik from './rubrik.png'
import rubrikWhite from './rubrik-white.png'
import palantir from './palantir.png'
import palantirWhite from './palantir-white.png'
import bytedance from './bytedance.png'
import bytedanceWhite from './bytedance-white.png'
import stackoverflow from './stackoverflow.png'
import stackoverflowWhite from './stackoverflow-white.png'

const TRACK_RECORDS = [
  {
    name: 'AirBnB',
    logo: airbnb,
    logoWhite: airbnbWhite,
    bg: '/images/airbnb.png',
    ipo: 'Dec, 2020 IPO',
    return: '2.9x',
    description:
      'Offering millions of places to stay and tens of thousands of handcrafted activities, all powered by local hosts.',
  },
  {
    name: 'Meituan',
    logo: meituan,
    logoWhite: meituanWhite,
    bg: '/images/meituan.png',
    ipo: 'Jul, 2018 IPO',
    return: '10x',
    description:
      "Helping people eat and live better. As China's leading e-commerce platform for services, Meituan apps provide entertainment and lifestyle services.",
  },
  {
    name: 'SpaceX',
    logo: spacex,
    logoWhite: spacexWhite,
    bg: '/images/spacex.png',
    ipo: 'Invested Apr, 2020',
    return: '3.63x',
    description:
      'Revolutionizing space technology through the design, manufacture, and launch of advanced rockets and spacecraft.',
  },
  {
    name: 'Discord',
    logo: discord,
    logoWhite: discordWhite,
    bg: '/images/discord.png',
    ipo: 'Invested May, 2021',
    description:
      'Cross-platform voice and text chat app designed specifically for gamers.',
  },
  {
    name: 'Rubrik',
    logo: rubrik,
    logoWhite: rubrikWhite,
    bg: '/images/rubrik.png',
    ipo: 'Invested Feb, 2021',
    return: '2.5x',
    description: 'Cloud data management and data security company.',
  },
  {
    name: 'Palantir',
    logo: palantir,
    logoWhite: palantirWhite,
    bg: '/images/palantir.png',
    ipo: 'Invested May, 2016',
    return: '2.5x',
    description:
      "Building the world's leading software for data-driven decisions and operations.",
  },
  {
    name: 'ByteDance',
    logo: bytedance,
    logoWhite: bytedanceWhite,
    bg: '/images/bytedance.png',
    ipo: 'Invested Dec, 2019',
    return: '7.5x',
    description:
      'Operating a range of content platforms that inform, educate, entertain, and inspire people across languages and cultures.',
  },
  {
    name: 'StackOverflow',
    logo: stackoverflow,
    logoWhite: stackoverflowWhite,
    bg: '/images/stackoverflow.png',
    return: '3.5x',
    description:
      'The largest and most trusted online community for developers to learn and share​ ​their programming ​knowledge and build their careers.',
  },
]

const RecordCard: React.FC<{
  record: (typeof TRACK_RECORDS)[0]
  isActive: boolean
  moveToIdx?: (
    idx: number,
    absolute?: boolean | undefined,
    animation?:
      | {
          duration?: number | undefined
          easing?: ((t: number) => number) | undefined
        }
      | undefined,
  ) => void
  idx: number
  activeIdx: number
}> = ({ record, isActive, activeIdx, moveToIdx, idx }) => {
  return (
    <>
      <div
        className={classNames(
          'keen-slider__slide pointer-events-none z-0 flex h-auto min-w-full items-center !overflow-visible',
          {
            'z-10 md:min-w-[720px]': isActive,
            '-z-10 md:min-w-[200px]': !isActive,
          },
        )}>
        <div
          className={classNames(
            'relative flex w-full flex-col !transition-none md:flex-row',
            {
              'md:translate-x-[260px] lg:translate-x-[520px]': activeIdx !== 0,
              'z-10 bg-mx-gray-dark shadow-2xl shadow-mx-purple-neon/20':
                isActive,
              'z-0 md:px-4': !isActive,
            },
          )}>
          {isActive ? (
            <div className="md flex w-full flex-col md:h-96 md:flex-row">
              <div className="relative h-40 md:h-full md:w-80 md:min-w-[20rem]">
                <Image
                  src={record.bg}
                  fill
                  alt={record.name}
                  className="object-cover"
                />
              </div>
              <div className="flex w-full flex-col font-body">
                <div className="flex h-full w-full flex-col justify-center p-8 md:p-0 md:px-9">
                  <div className="relative mb-8 h-9 w-32">
                    <Image
                      src={record.logoWhite}
                      fill
                      alt={record.name}
                      className="object-contain object-left"
                    />
                  </div>
                  <p className="text-left text-white">{record.description}</p>
                </div>
                <div className="flex flex-col md:flex-row">
                  {record.ipo && (
                    <div className="flex h-16 w-full items-center justify-center whitespace-nowrap bg-mx-blue px-6 text-lg">
                      <p className="text-white">{record.ipo}</p>
                    </div>
                  )}
                  {record.return && (
                    <div className="flex h-16 w-full items-center justify-center whitespace-nowrap bg-mx-purple-dark px-6 text-lg">
                      <p className="text-3xl font-semibold text-white">
                        {record.return}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <button
                className="pointer-events-auto relative h-12 w-full"
                onClick={() => {
                  if (moveToIdx) {
                    moveToIdx(idx, true)
                  }
                }}>
                <Image
                  src={record.logo}
                  fill
                  alt={record.name}
                  className="object-contain"
                />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export const TrackRecordSection: React.FC<{
  records?: typeof TRACK_RECORDS
}> = ({ records = TRACK_RECORDS }) => {
  const [details, setDetails] = React.useState<TrackDetails | null>(null)
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      created(s) {
        setDetails(s.track.details)
      },
      slideChanged(s) {
        setDetails(s.track.details)
      },
      slides: { perView: 'auto', spacing: 20, origin: 'center' },
      defaultAnimation: {
        duration: 600,
      },
      initial: 0,
      breakpoints: {
        '(min-width: 1024px)': {
          slides: { perView: 'auto', spacing: 30 },
        },
      },
    },
    [
      slider => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 3000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ],
  )

  return (
    <PartialContainer className="mb-28 mt-20">
      <div className="pb-8 lg:max-w-2xl">
        <motion.h2
          initial={{
            opacity: 0,
            x: -40,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
          }}
          className="mb-4 max-w-3xl font-heading text-5xl lg:text-6xl">
          Our track record
        </motion.h2>
        <motion.p
          initial={{
            opacity: 0,
            x: -40,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.1,
          }}
          className="max-w-3xl font-body text-lg text-mx-gray-dark">
          MarketX has an enviable investment record with some of the biggest
          names in Silicon Valley. Let us be your doorway to the next generation
          of start-ups.
        </motion.p>
      </div>
      <div ref={sliderRef} className="keen-slider">
        {records.map((record, idx) => (
          <RecordCard
            key={idx}
            record={record}
            isActive={(details?.rel || 0) === idx}
            moveToIdx={instanceRef?.current?.moveToIdx}
            idx={idx}
            activeIdx={details?.rel || 0}
          />
        ))}
      </div>
    </PartialContainer>
  )
}
