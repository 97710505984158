import React, { useContext } from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import { PartialContainer, Chevron, Button } from 'components'
import dealsBg from './home-deals-bg.svg'
import grainsbg from './grains.png'
import { ICompanyWithDeal } from '@types'
import { AuthContext } from 'context/auth'
import { abbreviateNumber } from 'utils'

import 'swiper/css'
import 'swiper/css/navigation'

const DealCardStat: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  const { authenticated } = useContext(AuthContext)
  return (
    <div className="flex w-full max-w-[120px] flex-col font-body">
      <span
        className={'mb-1 select-none text-2xl text-mx-purple-dark blur-[6px]'}>
        {value}
      </span>
      <span className="text-xs font-light text-mx-gray-dark">{title}</span>
    </div>
  )
}

const DealCard: React.FC<{ deal: ICompanyWithDeal }> = ({ deal }) => {
  const { authenticated } = useContext(AuthContext)

  const randomProgress = (input: string) =>
    ((input || '')
      .substring(0, 4)
      .split('')
      .map(c => c.charCodeAt(0))
      .reduce((a, b) => a + b) %
      75) +
    19

  return (
    <div className="flex h-full w-full flex-col overflow-hidden xl:flex-row">
      <div className="relative h-40 w-full overflow-clip xl:h-[435px] xl:w-[264px] xl:min-w-[264px]">
        <Image
          fill
          src={deal?.bgUrl?.url as string}
          blurDataURL={deal?.bgUrl?.blurUpThumb}
          alt={deal?.name}
          style={{
            backgroundImage: `url(${grainsbg.src})`,
          }}
          className="object-cover"
        />
        <div
          className="absolute inset-0 flex items-center justify-center bg-repeat"
          style={{
            backgroundImage: `url(${grainsbg.src})`,
          }}>
          <div className="relative flex h-28 w-28 items-center justify-center bg-white p-2 xl:h-36 xl:w-36">
            <Image
              src={deal?.logoUrl?.url as string}
              fill
              alt="Background"
              className="w-full object-contain"
            />
          </div>
        </div>
      </div>
      <div className="flex h-full w-full flex-col bg-white p-4 xl:py-8 xl:pl-9 xl:pr-0">
        <div className="flex items-baseline justify-between sm:flex-col lg:flex-row xl:pr-8">
          <h3 className="font-body text-3xl font-normal text-mx-gray-dark xl:text-4xl">
            {deal?.shortName ? deal?.shortName : deal?.name}
          </h3>
          <div className="flex items-center gap-2 font-body text-xs font-light uppercase text-black xl:text-sm">
            {deal?.sectors && <span>{deal?.sectors[0]?.name}</span>}
            <span className="h-2 w-2 rounded-full bg-gray-200" />
            <span>{deal?.region?.name}</span>
          </div>
        </div>
        <div className="mt-auto flex flex-col justify-start gap-4 border-b py-3 xl:flex-row xl:items-center xl:py-6 xl:pr-8">
          <DealCardStat
            value={abbreviateNumber(deal?.valuation)}
            title="VALUATION"
          />
          <p className="line-clamp-2 max-w-xs font-body text-sm font-light text-mx-gray-dark md:text-lg xl:line-clamp-none xl:text-base">
            {deal?.hook}
          </p>
        </div>
        <div className="flex flex-col justify-start gap-4 border-b py-3 xl:flex-row xl:items-center xl:py-6 xl:pr-8">
          <DealCardStat
            value={abbreviateNumber(deal?.allocation)}
            title="ALLOCATION"
          />
          <div className="flex w-full flex-col items-end">
            <div className="h-2 w-full rounded-full bg-gray-300">
              <div
                style={{
                  width: `${randomProgress(deal?.name)}%`,
                }}
                className="h-2 rounded-full bg-mx-purple-dark"
              />
            </div>
            <p className="mt-1 font-body text-base font-normal text-mx-purple-dark">
              {randomProgress(deal?.name)}% allocated
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-start gap-4 pt-3 xl:flex-row xl:items-center xl:pr-8 xl:pt-6">
          <DealCardStat
            value={abbreviateNumber(deal?.minimumInvestment)}
            title="MIN INVEST"
          />
          <Button
            newButton
            newButtonSize="small"
            className="w-full font-light"
            href={
              authenticated
                ? `/invest/company/${deal?.slug}`
                : `/signup?loginRedirect=/invest/company/${deal?.slug}`
            }>
            {authenticated ? 'Invest now' : 'Request access'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export const ExploreDealsSection: React.FC<{
  deals: ICompanyWithDeal[]
}> = ({ deals }) => {
  return (
    <div
      className="relative w-full overflow-x-hidden bg-mx-gray-dark bg-contain bg-repeat pb-16 pt-96 md:pt-48 xl:h-[1300px] xl:pb-0 xl:pt-0"
      style={{
        backgroundImage: `url(${grainsbg.src})`,
      }}>
      <PartialContainer className="relative h-full">
        <div className="mb-14 flex h-full w-full flex-col justify-center xl:max-w-lg xl:pr-20 1.5xl:pr-0">
          <motion.h2
            initial={{
              opacity: 0,
              x: -40,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
            }}
            className="mb-4 max-w-3xl font-heading text-4xl text-white md:text-5xl lg:text-6xl">
            Explore exclusive deals internationally
          </motion.h2>
          <motion.p
            initial={{
              opacity: 0,
              x: -40,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
            className="max-w-3xl font-body text-white md:text-lg">
            MarketX Ventures provides clients with a streamlined way to gain
            access to evaluate, invest, and exit opportunities in the
            growth-stage and pre-IPO investment space.
          </motion.p>
          <div className="mt-16 flex w-full items-center justify-between">
            <motion.div
              initial={{
                opacity: 0,
                x: -40,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.1,
              }}
              className="">
              <Button newButton href="/invest/companies" className="w-fit">
                Explore all deals
              </Button>
            </motion.div>
            <div className="mr-4 flex rotate-90 flex-col gap-y-2 xl:rotate-0 2xl:absolute 2xl:right-1/2 2xl:z-10 2xl:-translate-x-1/2">
              <button className="slider-button-prev flex h-8 w-8 items-center justify-center xl:h-10 xl:w-10">
                <Chevron fill="fill-themeBlue" size="h-8 w-8 xl:h-10 xl:w-10" />
              </button>
              <button className="slider-button-next flex h-8 w-8 items-center justify-center xl:h-10 xl:w-10">
                <Chevron
                  direction="down"
                  fill="fill-themeBlue"
                  size="h-8 w-8 xl:h-10 xl:w-10"
                />
              </button>
            </div>
          </div>
        </div>

        <div className="relative w-full xl:absolute xl:bottom-0 xl:right-0 xl:top-0 xl:w-[55%] 2xl:left-1/2 2xl:right-auto 2xl:w-6/12">
          <Swiper
            className="h-full w-full"
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={800}
            centeredSlides={true}
            loop={true}
            navigation={{
              nextEl: '.slider-button-next',
              prevEl: '.slider-button-prev',
            }}
            slidesPerView="auto"
            spaceBetween={24}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              960: {
                slidesPerView: 3,
              },
              1280: {
                centeredSlides: false,
                autoHeight: false,
                height: 1400,
                autoplay: {
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                },
                direction: 'vertical',
                slidesPerView: 3,
                spaceBetween: 48,
              },
            }}
            modules={[Autoplay, Navigation]}>
            {deals.map((deal, idx) => (
              <SwiperSlide virtualIndex={idx} key={idx}>
                <DealCard deal={deal} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="pointer-events-none absolute inset-0 z-10 hidden bg-gradient-to-r from-mx-gray-dark/40 via-transparent to-mx-gray-dark/40 sm:block xl:bg-gradient-to-b xl:from-mx-gray-dark/70 xl:via-transparent xl:to-mx-gray-dark/70" />
        </div>
      </PartialContainer>
      <Image
        src={dealsBg}
        alt="background"
        role="presentation"
        className="absolute bottom-0 right-1/2 hidden xl:block"
      />
    </div>
  )
}
