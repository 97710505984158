import * as React from 'react'
import { motion } from 'framer-motion'
import { PartialContainer } from 'components'
import Image from 'next/image'
import globalPresenceImg from './global-presence.png'

export const GlobalPresenceSection = () => {
  return (
    <PartialContainer className="my-24">
      <motion.div
        initial={{
          opacity: 0,
          y: -100,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
        }}
        className="mx-auto max-w-3xl">
        <h2 className="mb-4 text-center font-heading text-4xl md:text-5xl lg:text-6xl">
          Global presence but within your reach
        </h2>
        <p className="mt-8 text-center font-body text-mx-gray-dark md:text-lg">
          The MarketX Ventures team is strategically located around the globe:
          with headquarters in the United States, we have offices in Asia,
          Europe, South America, and the Middle East.
        </p>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
        }}
        className="mt-20">
        <Image
          className="hidden w-full md:block"
          src={globalPresenceImg}
          alt="World Map showing MarketX Ventures presence"
        />
      </motion.div>
    </PartialContainer>
  )
}
