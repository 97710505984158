import { useState, useEffect } from 'react'
import { AppLayout, WhyMarketX } from '@components'
import { HeroSection } from '@partials/homepage/hero'
import { PartnersSection } from '@partials/homepage/partners'
import { ExploreDealsSection } from '@partials/homepage/explore-deals'
import { GlobalPresenceSection } from '@partials/homepage/global-presence'
import { WhatIsMarketXSection } from '@partials/homepage/what-is-mx'
import { FeaturedInSection } from '@partials/homepage/featured-in'
import { VideoLessonSection } from '@partials/homepage/video-lessons'
import { TrackRecordSection } from '@partials/homepage/track-record'
import { InvestWithCrypto } from '@partials/homepage/crypto'
import { PortfolioSection } from '@partials/homepage/portfolio'
import { LpSummitSection } from '@partials/homepage/lp-summit'
import { LetUsGuideYouSection } from '@partials/homepage/let-us-guide-you'
import type { NextPage, GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'
import { getGraphQLData } from '../database/graphQL/apollo'
import { ICompany, ICompanyWithDeal } from '@types'
import { queryCompanyList } from '@queries'
import axios from 'axios'
import { PuffLoader } from 'react-spinners'
import { Mixpanel } from '@utils'

export const getStaticProps: GetStaticProps = async () => {
  const response = await getGraphQLData<{ allCompanies: ICompany[] }>(
    queryCompanyList,
  )

  return {
    props: { companies: response?.allCompanies || null },
    revalidate: 10,
  }
}

const Home: NextPage<{
  companies: ICompany[]
}> = ({ companies }) => {
  const [companiesWithMainDeal, setCompaniesWithMainDeal] = useState<
    ICompanyWithDeal[]
  >(companies ? [...companies] : [])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_API_URL + '/companies/mainDeals')
      .then(response => {
        const companyDeals = response.data

        let companyWithDealData: ICompanyWithDeal[] = []
        companies.filter(company => {
          const companyWithDeal = companyDeals.find(
            (companyWithDeal: ICompanyWithDeal) =>
              company.slug === companyWithDeal.slug,
          )
          if (companyWithDeal) {
            companyWithDealData.push({
              ...company,
              ...companyWithDeal.deals[0],
            })
          }
          return companyWithDealData
        })
        setCompaniesWithMainDeal(companyWithDealData)
        setLoading(false)
      })
      .catch(err => {
        console.log('err', err)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    Mixpanel.track('Visit Home')
  }, [])

  return (
    <>
      <NextSeo
        title="Private Equity Investment Management Platform"
        titleTemplate="MarketX Ventures | %s"
        description="MarketX Ventures is a one-stop shop for over 300 family offices, funds, and
        high net worth individuals to access high growth investment
        opportunities."
        canonical="https://www.marketxventures.com/"
      />
      <AppLayout>
        <HeroSection />
        <PartnersSection />
        <LpSummitSection />
        <PortfolioSection />
        {loading || !companiesWithMainDeal ? (
          <div className="mt-2 flex items-center justify-center">
            <PuffLoader size={22} color={'black'} />
          </div>
        ) : (
          <ExploreDealsSection deals={companiesWithMainDeal} />
        )}
        <GlobalPresenceSection />
        <WhatIsMarketXSection />
        <FeaturedInSection />
        <VideoLessonSection />
        <TrackRecordSection />
        <WhyMarketX />
        <InvestWithCrypto />
        <LetUsGuideYouSection />
      </AppLayout>
    </>
  )
}

export default Home
