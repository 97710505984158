import Image from 'next/image'
import Marquee from 'react-fast-marquee'
import { PartialContainer } from 'components'
import Styles from './featured-in.module.css'

const featured = [
  {
    id: 1,
    logo: 'https://mx-documents.s3.amazonaws.com/forbes.png',
    alt: 'Forbes',
    url: 'https://www.forbes.com/sites/pamelaambler/2019/04/01/forbes-30-under-30-asia-2019-the-future-of-finance-is-more-female/',
  },
  {
    id: 2,
    logo: 'https://mx-documents.s3.amazonaws.com/cnbc.png',
    alt: 'cnbc',
    url: 'https://www.cnbc.com/2017/10/30/marketx-lets-chinese-investors-pour-cash-into-pre-ipo-tech-firms.html',
  },
  {
    id: 3,
    logo: 'https://mx-documents.s3.amazonaws.com/yahoo.png',
    alt: 'yahoo',
    url: 'https://finance.yahoo.com/news/start-allows-chinese-investors-pour-123756249.html?guccounter=1',
  },
  {
    id: 4,
    logo: 'https://mx-documents.s3.amazonaws.com/finovate.png',
    alt: 'finovate',
    url: 'https://finovate.com/videos/finovatefall-2016-marketx/',
  },
  {
    id: 5,
    logo: 'https://mx-documents.s3.amazonaws.com/street-asia.png',
    alt: 'Deal Street Asia',
    url: 'https://www.dealstreetasia.com/stories/marketx-pre-ipo-tech-fund-133941/',
  },
  {
    id: 6,
    logo: 'https://mx-documents.s3.amazonaws.com/eban.png',
    alt: 'Eban',

    url: 'https://www.slideshare.net/CathrynChen/memorandum-of-understanding-marketx',
  },
  {
    id: 7,
    logo: 'https://mx-documents.s3.amazonaws.com/bankles.png',
    alt: 'bankles',
    url: '',
  },
  {
    id: 8,
    logo: 'https://mx-documents.s3.amazonaws.com/fintech.png',
    alt: 'fintech',
    url: 'https://www.fintechsv.com/blog/video-interview-with-cathryn-chen-ceocofounder-marketx/',
  },
]

export const FeaturedInSection = () => {
  return (
    <PartialContainer className="py-8">
      <div className="relative">
        <div className="absolute bottom-0 left-0 top-0 z-10 hidden items-center justify-center border-r border-r-black/75 bg-white pr-8 md:flex">
          We’re featured in
        </div>

        <Marquee gradient={false} className={`${Styles.marquee}`}>
          {featured.map((item, idx) => (
            <a
              href={item.url}
              key={idx}
              className="relative mx-4 block h-20 w-20 md:mx-8">
              <Image
                src={item.logo}
                alt={item.alt}
                fill
                className="z-0 object-contain"
              />
            </a>
          ))}
        </Marquee>
      </div>
    </PartialContainer>
  )
}
