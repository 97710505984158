import { motion } from 'framer-motion'
import { PartialContainer } from 'components'
import * as React from 'react'
import Styles from './portfolio.module.css'

const Stat: React.FC<{
  title: string
  subtitle: string
}> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-row items-center gap-4 font-body md:max-w-xs md:flex-col md:gap-2">
      <h4 className="min-w-[144px] text-4xl text-mx-purple-dark md:text-center lg:text-5xl">
        {title}
      </h4>
      <p className="min-w-[144px] text-sm md:text-center lg:text-base">
        {subtitle}
      </p>
    </div>
  )
}

export const PortfolioSection = () => {
  return (
    <PartialContainer>
      <div className="relative w-full">
        <motion.div
          initial={{
            opacity: 0,
            y: '0',
          }}
          whileInView={{
            opacity: 1,
            y: -120,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
          }}
          className={`${Styles.portfolioShadow} absolute left-0 right-0 top-0 z-20 w-full bg-white p-8 md:p-12`}>
          <h3 className="mb-12 font-body text-2xl md:text-center md:text-3xl">
            Create your own Private Markets Portfolio with MarketX Ventures
          </h3>
          <div className="flex flex-col justify-around gap-8 md:flex-row md:items-stretch">
            <Stat title="$250M+" subtitle="Investments closed" />
            <span className="block h-px w-full self-stretch bg-mx-purple-dark md:h-auto md:w-px" />
            <Stat
              title="$750B"
              subtitle="Estimated total market cap of transacted companies"
            />
            <span className="block h-px w-full self-stretch bg-mx-purple-dark md:h-auto md:w-px" />
            <Stat title="300+" subtitle="Companies served" />
          </div>
        </motion.div>
      </div>
    </PartialContainer>
  )
}
