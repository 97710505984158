export const usersData = [
  {
    id: 1,
    stack: 'E-commerce',
    details:
      'The e-commerce technology market is poised to grow by $ 5.18 billion through 2024 progressing at a forecasted CAGR of 12%. The market is driven by the increasing e-commerce sales and growing need to streamline business processes. In addition, increasing e-commerce sales is anticipated to boost the growth of the market as well.',
    videoURL: 'https://www.youtube.com/embed/vH-Q9lI-pb4',
    talksGroup: [
      {
        id: 1,
        name: 'Carlos Cashman',
        position: 'Co-Founder and Co-CEO at Thrasio',
        img: 'https://mx-documents.s3.amazonaws.com/Carlos-Cashman-2.jpeg',
      },
    ],
  },
  {
    id: 2,
    stack: 'MediaTech / Entertainment Tech',
    details:
      'The U.S. media and entertainment (M&E) industry is the largest in the world. At $717 billion, it represents a third of the global M&E industry, and it includes motion pictures, television programs and commercials, streaming content, music and audio recordings, broadcast, radio, book publishing, video games, and ancillary services and products. The U.S. industry is expected to reach more than $825 billion by 2023, according to the (PwC).',
    videoURL: 'https://www.youtube.com/embed/bgueKKi3TYs',
    talksGroup: [
      {
        id: 1,
        name: 'Sunny Dhillon',
        position: 'Partner at Signia Venture Partners',
        img: 'https://mx-documents.s3.amazonaws.com/sunny_dhillon.jpeg',
      },
    ],
  },
  {
    id: 3,
    stack: 'NFT / Cryptocurrencies',
    details:
      'As of today, $BTC’s market cap is $588B while $ETH’s market cap is $219B. $BTC and $ETH are two of the most widely traded cryptocurrencies, and together account for over 60% of all cryptocurrency day trading around the world. $BTC has a supply limit at 21 million, which is why it has become a very attractive investment for both amateur and expert crypto bulls alike. Similarly, since the beginning of the year, NFT sales have surged to $2B in just Q1 alone, representing an increase of 2100% since Q4 of 2020. Tesla, Square, and Coinbase are the most notable public companies that have accepted transactions in $BTC since 2021. ',
    videoURL: 'https://www.youtube.com/embed/yPMXReEC7ew',
    talksGroup: [
      {
        id: 1,
        name: 'Joey Krug',
        position: 'Chief Investment Officer at Pantera Capital',
        img: 'https://mx-documents.s3.amazonaws.com/joey_krug.jpeg',
      },
      {
        id: 2,
        name: 'Yida Gao',
        position: 'General Partner at Shima Capital',
        img: 'https://mx-documents.s3.amazonaws.com/yida_gao.jpg',
      },
    ],
  },
  {
    id: 4,
    stack: 'Fintech',
    details:
      'In recent years, we have seen a ballooning of activity in fintech — an expansive term applied to technology-driven disruptions in financial services, where financial companies and startups use artificial intelligence (AI) and other tech in their day-to-day processes. As consumers increasingly turn to alternative, digital methods of managing their finances, tech-savvy startups and traditional financial institutions (FIs) alike are diving into the fintech industry. And investors would be wise to take note of this digital shift. The Global Fintech Market is anticipated to grow at a CAGR of around 20% over the next four years. The market value is expected to reach around $ 305 billion by 2025, according to GlobeNewswire.',
    videoURL: 'https://www.youtube.com/embed/wZyF1ln6Wnc',
    talksGroup: [
      {
        id: 1,
        name: 'Daniel Kahn',
        position: 'Global Open Finance at Plaid',
        img: 'https://mx-documents.s3.amazonaws.com/Dan_Kahn_headshot.png',
      },
      {
        id: 2,
        name: 'Jackson Gates',
        position: 'Investor at Manresa Ventures',
        img: 'https://mx-documents.s3.amazonaws.com/Jackson_Gates_med_res.jpg',
      },
      {
        id: 3,
        name: 'Yue An',
        position: 'Director of Quantitative Markets at Affirm',
        img: 'https://mx-documents.s3.amazonaws.com/anonymous.png',
      },
    ],
  },
  {
    id: 5,
    stack: 'Enterprise Tech',
    details:
      'Enterprise technology streamlines workflows, improves communication, and provides access to data. At best, enterprise technology makes it easier for the entire organization to work seamlessly and achieve its goals. Enterprise technology refers to the concept of information technology (IT) resources and data that are shared across an enterprise. Efforts like IT strategy, portfolio management, and IT governance enable this idea to function effectively. Some samples of enterprise technology include Content management systems and CRMs.',
    videoURL: 'https://www.youtube.com/embed/9s6cn_vljS4',
    talksGroup: [
      {
        id: 1,
        name: 'Jason Kong',
        position: 'Partner at SurgoCap Partners',
        img: 'https://mx-documents.s3.amazonaws.com/Jason_Kong.jpeg',
      },
      {
        id: 2,
        name: 'Justin Manikas',
        position: 'VP of Partnerships at Nomad Data',
        img: 'https://mx-documents.s3.amazonaws.com/Justin.jpg',
      },
      {
        id: 3,
        name: 'Domenic Perri',
        position: 'Partner at Vertex Ventures',
        img: 'https://mx-documents.s3.amazonaws.com/Dom_Perri.jpg',
      },
    ],
  },
  {
    id: 6,
    stack: 'AI / ML and Deep Tech',
    details:
      "The emerging trends in AI-driven automation reflect significant shifts of players and actions within the AI sphere that reveal the reconfigurations of ideas, interests, influence, and investments within the AI domain. Enterprises are starting to understand the results of the evolving AI-driven automation ecosystem far beyond narrow artificial intelligence, crossing economic, commerce, education, governance, and trade supply chains. While the connection between enterprises and automation is complicated and sometimes indirect, the force and pace of AI-driven automation change expected within the coming years will present each enterprise's challenges and opportunities for its: products, services, processes, operations, and supply chains. From what it seems, the AI applications of tomorrow are going to be hybrid systems composed of several components and reliant on many various data sets, methodologies, and models.",
    videoURL: 'https://www.youtube.com/embed/lZV6lIC_308',
    talksGroup: [
      {
        id: 1,
        name: 'Raviraj Jain',
        position: 'Partner at Lightspeed Venture',
        img: 'https://mx-documents.s3.amazonaws.com/ravinder_jain.jpeg',
      },
      {
        id: 2,
        name: 'Hyuk Jeen Suh',
        position: 'Skyrider Ventures',
        img: 'https://mx-documents.s3.amazonaws.com/Hyuk_Jeen_Suh.jpeg',
      },
      {
        id: 3,
        name: 'Kenneth Chew',
        position: 'Venture Capitalist',
        img: 'https://mx-documents.s3.amazonaws.com/Kenneth_Chew.png',
      },
      {
        id: 4,
        name: 'Andrew Manoske',
        position: 'Principal Product Manager at HashiCorp',
        img: 'https://mx-documents.s3.amazonaws.com/Andrew_Manoske.webp',
      },
    ],
  },
  {
    id: 7,
    stack: 'Mobility / Logistics',
    details:
      'In early 2018 there were 22 travel and mobility unicorns. By last month the number had grown to 44. The top categories in the mobility area are: ride hailing, with 11 unicorns (25.0%); autonomous vehicles, with ten (22.7%); and micromobility, with three (6.8%). The remaining 20 unicorns are in the travel category ( such as hotels, and bookings services). As we all know, Mobility technology is more than just autonomous vehicles, ride hailing, e-scooters and e-bikes. It also includes: electrification (which entails electric vehicles, batteries, charging, etc); Mobility also includes fleet management and connectivity ( such as data management, cybersecurity, parking,); auto commerce (car sharing); transportation logistics (freight, last-mile delivery); and last but not least urban air mobility.',
    videoURL: 'https://www.youtube.com/embed/RXB1aXtgmSw',
    talksGroup: [
      {
        id: 1,
        name: 'Cheng Lu',
        position: 'CEO at TuSimple',
        img: 'https://mx-documents.s3.amazonaws.com/Cheng_lu.png',
      },
      {
        id: 2,
        name: 'Lidiya Dervisheva',
        position: 'Partner at Next47',
        img: 'https://mx-documents.s3.amazonaws.com/Lidiya.jpg',
      },
      {
        id: 3,
        name: 'Marco Marinucci',
        position: 'Partner at Hella Ventures',
        img: 'https://mx-documents.s3.amazonaws.com/Marco_Marinucci.jpg',
      },
    ],
  },
]
