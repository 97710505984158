import * as React from 'react'
import Link from 'next/link'
import Image, { StaticImageData } from 'next/image'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'
import { Autoplay } from 'swiper'
import { PartialContainer, Button } from 'components'
import iraIllustration from './images/ira-banner.png'
import inflationHedgeIllustration from './images/inflation-hedge-banner.png'
import masterExchangeIllustration from './images/master-exchange.png'
import rimacIllustration from './images/rimac.png'
import gojekIllustration from './images/gojek.png'
import stripeIllustration from './images/stripe.png'
import spacexIllustration from './images/spacex.png'
import { AuthContext } from 'context/auth'
import Styles from './hero.module.css'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

SwiperCore.use([EffectFade])

interface ICarouselEntry {
  src: StaticImageData
  alt: string
  href?: string
}

const carouselEntries: ICarouselEntry[] = [
  { src: stripeIllustration, alt: 'stripe' },
  { src: spacexIllustration, alt: 'spacex' },
  { src: rimacIllustration, alt: 'rimac' },
  {
    src: inflationHedgeIllustration,
    alt: 'Inflation Hedging',
    href: '/inflationhedge',
  },
  { src: iraIllustration, alt: 'Individual Retirement Account' },
  { src: gojekIllustration, alt: 'gojek' },
  { src: masterExchangeIllustration, alt: 'master-exchange' },
]

const Illustrations = () => {
  const swiperOptions = {
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    effect: 'fade' as const,
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
  }

  const CarouselEntry = ({ entry }: { entry: ICarouselEntry }) => (
    <Image
      src={entry.src}
      alt={entry.alt}
      fill
      priority
      className="z-0 object-contain"
    />
  )

  return (
    <Swiper
      modules={[Autoplay]}
      {...swiperOptions}
      className="h-full min-h-[300px] w-full md:min-h-[500px]">
      {carouselEntries.map(entry => (
        <SwiperSlide key={entry.alt} className="relative">
          {entry.href ? (
            <Link href={entry.href}>
              <CarouselEntry entry={entry} />
            </Link>
          ) : (
            <CarouselEntry entry={entry} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export const HeroSection = () => {
  const { authenticated } = React.useContext(AuthContext)
  return (
    <PartialContainer className="grid grid-cols-1 gap-8 py-16 lg:grid-cols-2">
      <div className="">
        <motion.h1
          initial={{
            opacity: 0,
            x: -40,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.5,
          }}
          className="mb-4 max-w-3xl font-heading text-4xl md:text-5xl lg:text-6xl">
          Your passport to{' '}
          <span className="mb-4 font-heading text-4xl leading-relaxed text-mx-purple-dark md:text-5xl lg:text-6xl">
            global private markets.
          </span>
        </motion.h1>
        <ul className="mx-blue my-8 list-inside list-disc space-y-3 font-body marker:w-4 marker:text-mx-blue md:my-16 md:text-lg">
          <motion.li
            initial={{
              opacity: 0,
              x: -40,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}>
            World-class Investment Advisors at your disposal
          </motion.li>
          <motion.li
            initial={{
              opacity: 0,
              x: -40,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.2,
            }}>
            Exclusive access to deals
          </motion.li>
          <motion.li
            initial={{
              opacity: 0,
              x: -40,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>
            Global Community of like minded investors
          </motion.li>
        </ul>
        <motion.div
          initial={{
            opacity: 0,
            x: -40,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.5,
            delay: 0.4,
          }}
          className="flex flex-wrap items-center gap-3 md:gap-5">
          {!authenticated ? (
            <Button newButton href="/signup">
              Start investing
            </Button>
          ) : (
            <Button newButton href="/invest/companies">
              Explore all deals
            </Button>
          )}
        </motion.div>
      </div>

      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          delay: 0.1,
        }}
        className="h-full w-full">
        <Illustrations />
      </motion.div>
    </PartialContainer>
  )
}
