import React from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { PartialContainer, Chevron } from 'components'
import { TrackDetails, useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import Styles from './video-lessons.module.css'
import { usersData } from '../../../conference_page_data'

export function parseYoutubeURL(url: string) {
  const regExp = /embed\/([a-zA-Z\-0-9]*)/
  const match = url.match(regExp)
  return match && match[1]
}

const LessonCard: React.FC<{
  lesson: any
  isActive: boolean
  next?: () => void
  prev?: () => void
  idx: number
  activeIdx: number
}> = ({ lesson, isActive, next, prev, activeIdx }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const videoId = parseYoutubeURL(lesson.videoURL)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div
        className={classNames(
          'keen-slider__slide pointer-events-none z-0 flex h-auto min-w-[80%] !overflow-visible md:items-end',
          {
            'z-10 md:min-w-[520px]': isActive,
            '-z-10 md:min-w-[320px]': !isActive,
          },
        )}>
        <div
          className={classNames(
            'pointer-events-auto relative flex flex-col !transition-none',
            {
              'md:translate-x-[100px] lg:translate-x-[200px]': activeIdx !== 0,
              'shadow-mx-neon-purple/10 z-10 shadow-xl md:-mb-8 md:min-w-[520px]':
                isActive,
              'md:min-w-[320px]': !isActive,
            },
          )}>
          {isActive && (
            <div className="hidden md:block">
              <button
                onClick={prev}
                className="pointer-events-auto absolute -left-10 top-24 z-10 flex h-8 w-8 items-center justify-center md:-left-12 md:h-10 md:w-10">
                <Chevron
                  direction="left"
                  fill="fill-mx-blue"
                  size="h-8 w-8 md:h-10 md:w-10"
                />
              </button>
              <button
                onClick={next}
                className="pointer-events-auto absolute -right-10 top-24 z-10 flex h-8 w-8 items-center justify-center md:-right-12 md:h-10 md:w-10">
                <Chevron
                  direction="right"
                  fill="fill-mx-blue"
                  size="h-8 w-8 md:h-10 md:w-10"
                />
              </button>
            </div>
          )}
          <div
            className={classNames('relative h-60 w-full', {
              'md:min-h-[320px]': isActive,
              'md:min-h-[240px]': !isActive,
            })}>
            <Image
              src={lesson.talksGroup[0].img}
              alt={lesson.talksGroup[0].name}
              fill
              className="object-cover"
            />
            <button
              onClick={openModal}
              className="group absolute inset-0 z-10 flex items-center justify-center">
              {isActive && (
                <span className="flex h-12 w-12 items-center justify-center rounded-full bg-mx-purple-dark transition-all group-hover:scale-125 group-hover:bg-mx-purple-neon">
                  <svg
                    className="ml-1 w-5"
                    viewBox="0 0 24 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5 13.5L0.5 27V0L23.5 13.5Z" fill="white" />
                  </svg>
                </span>
              )}
            </button>
          </div>
          <div
            className={classNames('bg-white font-body text-mx-gray-dark', {
              'px-4 py-4 md:py-12': isActive,
              'px-4 py-4 md:px-0': !isActive,
            })}>
            <h4 className="text-2xl">{lesson.talksGroup[0].name}</h4>

            <h5 className={classNames('mt-1')}>
              {lesson.talksGroup[0].position}
            </h5>
            <p
              className={classNames('mt-4', {
                'line-clamp-6': isActive,
                'md:line-clamp-2': !isActive,
              })}>
              {lesson.details}
            </p>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden bg-black align-middle shadow-xl transition-all">
                  <iframe
                    className="aspect-video h-full w-full"
                    src={`https://www.youtube.com/embed/${videoId}?&autoplay=1`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export const VideoLessonSection: React.FC<{}> = ({}) => {
  const [details, setDetails] = React.useState<TrackDetails | null>(null)
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,

      created(s) {
        setDetails(s.track.details)
      },
      slideChanged(s) {
        setDetails(s.track.details)
      },
      slides: { perView: 'auto', spacing: 20, origin: 'center' },
      defaultAnimation: {
        duration: 300,
      },
      initial: 0,
      breakpoints: {
        '(min-width: 1024px)': {
          slides: { perView: 'auto', spacing: 20, origin: 0.4 },
        },
        '(min-width: 1280px)': {
          slides: { perView: 'auto', spacing: 20, origin: 0.5 },
        },
      },
    },
    [
      slider => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 3000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ],
  )

  return (
    <div className="mb-8 mt-20">
      <PartialContainer className="">
        <div className="pb-8 lg:max-w-md xl:-mb-14 xl:pb-0">
          <motion.h2
            initial={{
              opacity: 0,
              x: -40,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
            }}
            className="mb-4 max-w-3xl font-heading text-4xl md:text-5xl lg:text-6xl">
            Video lessons that inspire
          </motion.h2>
          <motion.p
            initial={{
              opacity: 0,
              x: -40,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
            className="max-w-3xl font-body text-mx-gray-dark md:text-lg">
            Listen to experienced entrepreneurs, venture capitalists, and early
            employees share their insights & stories on investing and startup
            building over the decades.
          </motion.p>
        </div>
      </PartialContainer>

      <div ref={sliderRef} className="keen-slider max-w-screen w-screen pb-24">
        {usersData.map((lesson, idx) => (
          <LessonCard
            key={idx}
            lesson={lesson}
            isActive={(details?.rel || 0) === idx}
            next={instanceRef?.current?.next}
            prev={instanceRef?.current?.prev}
            idx={idx}
            activeIdx={details?.rel || 0}
          />
        ))}
      </div>
    </div>
  )
}
