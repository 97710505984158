import * as React from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { PartialContainer, Button } from 'components'
import lpSummitImg from './lp-summit.png'
import Link from 'next/link'

export const LpSummitSection = () => {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-white">
      <PartialContainer className="grid grid-cols-1 gap-8 py-16 pb-44 lg:grid-cols-2">
        <div className="">
          <motion.h2
            initial={{
              opacity: 0,
              x: -40,
            }}
            viewport={{ once: true }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
            }}
            className="mb-4 font-heading text-4xl md:text-5xl lg:text-6xl">
            MarketX Ventures <br />
            <span className="mb-4 font-heading text-4xl leading-relaxed text-mx-purple-dark md:text-5xl lg:text-6xl">
              Annual LP Summit
            </span>
          </motion.h2>
          <motion.p
            initial={{
              opacity: 0,
              x: -40,
            }}
            viewport={{ once: true }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
            className="my-8 max-w-lg font-body md:my-16 md:text-lg">
            MarketX Ventures hosted our annual LP Summit in San Francisco. The
            insights shared across these panels are not just
            thought-provoking—they are actionable intelligence that can guide
            our investment strategies and technological focus for the years to
            come. We are thrilled to present the recorded panels for those who
            couldn&apos;t attend in person, and we hope you find them as
            enlightening as we did.
          </motion.p>
          <motion.div
            initial={{
              opacity: 0,
              x: -40,
            }}
            viewport={{ once: true }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.4,
            }}
            className="flex flex-wrap items-center gap-3 md:gap-5">
            <Link href="/news/watch-now-all-panel-discussions-from-the-2023-technology-and-innovation-summit">
              <Button size="xl">Watch Now: All Panel Discussions</Button>
            </Link>
          </motion.div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          viewport={{ once: true }}
          whileInView={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
          className="relative h-full min-h-[300px] w-full md:min-h-[500px]">
          <Image
            src={lpSummitImg}
            alt={'Cathyrn Chen, Founder of MarketX Ventures'}
            fill
            priority
            className="z-0 object-contain"
          />
        </motion.div>
      </PartialContainer>
    </div>
  )
}
