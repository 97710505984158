import * as React from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'
import { Autoplay } from 'swiper'
import { PartialContainer, Button } from 'components'
import bnbLogo from './bnb.png'
import btcLogo from './btc.png'
import ethLogo from './eth.png'
import mxLogo from './mx.png'
import bg from './bg.svg'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

SwiperCore.use([EffectFade])

const images = [
  { src: btcLogo, alt: 'BTC' },
  { src: ethLogo, alt: 'ETH' },
  { src: bnbLogo, alt: 'BNB' },
]

const CryptoLogos = () => {
  const swiperOptions = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    effect: 'fade' as const,
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
  }

  return (
    <Swiper
      modules={[Autoplay]}
      {...swiperOptions}
      className="!m-0 h-36 w-36 sm:h-40 sm:w-40 lg:h-48 lg:w-48">
      {images.map(image => (
        <SwiperSlide key={image.alt} className="relative">
          <Image
            src={image.src}
            alt={image.alt}
            fill
            className="z-0 rounded-full object-contain"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export const InvestWithCrypto = () => {
  return (
    <div className="relative bg-gradient-to-r from-gray-50 to-white">
      <motion.div
        initial={{
          opacity: 0,
        }}
        viewport={{ once: true }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
        }}>
        <Image
          fill
          src={bg}
          alt="Background"
          className="hidden -translate-y-4 md:block"
        />
      </motion.div>
      <PartialContainer className="grid grid-cols-1 gap-4 py-16 md:grid-cols-2">
        <div>
          <motion.h2
            initial={{
              opacity: 0,
              x: -40,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
            }}
            className="mb-4 max-w-xl font-heading text-4xl md:text-5xl lg:text-6xl">
            Now invest with Crypto too
          </motion.h2>

          <motion.p
            initial={{
              opacity: 0,
              x: -40,
            }}
            viewport={{ once: true }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
            className="mt-8 max-w-xl font-body md:mt-36 md:text-lg">
            Evaluate our available opportunities and connect your crypto wallet
            to fund your investment.
          </motion.p>
          <motion.div
            initial={{
              opacity: 0,
              x: -40,
            }}
            viewport={{ once: true }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.2,
            }}
            className="relative my-8 md:mb-0 md:mt-16">
            <Button newButton href="/invest/companies" className="w-fit">
              Explore all deals
            </Button>
          </motion.div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          viewport={{ once: true }}
          whileInView={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
          }}
          className="flex items-center gap-8 sm:gap-16 md:-mt-24 md:justify-center md:gap-20">
          <div className="absolute bottom-32 left-0 right-0 h-40 translate-y-20 md:hidden">
            <Image fill src={bg} alt="Background" className="object-cover" />
          </div>
          <CryptoLogos />
          <div className="relative h-36 w-36 sm:h-40 sm:w-40 lg:h-48 lg:w-48">
            <Image
              src={mxLogo}
              alt="MarketX"
              fill
              className="z-0 rounded-full object-contain"
            />
          </div>
        </motion.div>
      </PartialContainer>
    </div>
  )
}
