import Image from 'next/image'
import Marquee from 'react-fast-marquee'
import atomicoImg from './atomico.png'
import gvImg from './gv.png'
import thielImg from './thiel.png'
import contrarianVenturesImg from './contrarian-ventures.png'
import lightspeedImg from './lightspeed.png'
import socialImpactCapitalImg from './social-impact-capital.png'
import sparkCapitalImg from './spark-capital.png'
import yCombinatorImg from './y-combinator.png'
import { PartialContainer } from 'components'

const partners = [
  { src: atomicoImg, alt: 'Atomico' },
  { src: gvImg, alt: 'GV' },
  { src: thielImg, alt: 'Thiel' },
  { src: contrarianVenturesImg, alt: 'Contrarian Ventures' },
  { src: lightspeedImg, alt: 'Lightspeed' },
  { src: socialImpactCapitalImg, alt: 'Social Impact Capital' },
  { src: sparkCapitalImg, alt: 'Spark Capital' },
  { src: yCombinatorImg, alt: 'Y Combinator' },
  { src: atomicoImg, alt: 'Atomico' },
  { src: gvImg, alt: 'GV' },
  { src: thielImg, alt: 'Thiel' },
  { src: contrarianVenturesImg, alt: 'Contrarian Ventures' },
  { src: lightspeedImg, alt: 'Lightspeed' },
  { src: socialImpactCapitalImg, alt: 'Social Impact Capital' },
  { src: sparkCapitalImg, alt: 'Spark Capital' },
  { src: yCombinatorImg, alt: 'Y Combinator' },
]

export const PartnersSection = () => {
  return (
    <PartialContainer className="py-8">
      <div className="relative">
        <div className="absolute bottom-0 left-0 top-0 z-10 hidden items-center justify-center border-r border-r-black/75 bg-white pr-8 font-body md:flex">
          Our Partners
        </div>

        <Marquee gradient={false}>
          {partners.map((image, idx) => (
            <div key={idx} className="relative mx-4 h-20 w-20 md:mx-6">
              <Image
                src={image.src}
                alt={image.alt}
                fill
                priority
                className="z-0 object-contain"
              />
            </div>
          ))}
        </Marquee>
      </div>
    </PartialContainer>
  )
}
