import * as React from 'react'
import { motion } from 'framer-motion'
import { PartialContainer } from 'components'
import Image from 'next/image'
import whatIsMxImg from './what-is-mx.png'
import { Dialog, Transition } from '@headlessui/react'

export const WhatIsMarketXSection = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const videoId = 'o4T2KEGVzvA'

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <PartialContainer className="mb-4 mt-24 lg:mb-2">
      <motion.h2
        initial={{
          opacity: 0,
          x: -40,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
        }}
        className="mb-4 font-body text-xl text-mx-purple-dark md:text-3xl">
        What is MarketX Ventures?
      </motion.h2>
      <motion.p
        initial={{
          opacity: 0,
          x: -40,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
          delay: 0.1,
        }}
        className="mb-16 max-w-3xl font-body text-2xl text-mx-black-light md:text-2xl ">
        Backing the next generation of founders who are building billion dollar
        companies from the most diverse backgrounds.
      </motion.p>
      <div className="flex w-full flex-col lg:flex-row lg:items-center">
        <motion.div
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.1,
          }}
          className="relative w-full">
          <Image
            src={whatIsMxImg}
            alt={'What is MarketX?'}
            className="w-full"
          />
          <button
            onClick={openModal}
            className="group absolute inset-0 z-10 flex items-center justify-center">
            <span className="flex h-12 w-12 items-center justify-center rounded-full bg-mx-purple-dark transition-all group-hover:scale-125 group-hover:bg-mx-purple-neon">
              <svg
                className="ml-1 w-5"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5 13.5L0.5 27V0L23.5 13.5Z" fill="white" />
              </svg>
            </span>
          </button>
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
          }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
          className="w-auto max-w-xl pt-10 lg:px-16 lg:pt-0 xl:px-20">
          <p className="mb-6 font-body text-2xl text-mx-purple-dark md:text-4xl lg:mb-10">
            “A global investment platform, focused on bringing both early stage
            and growth stage secondary opportunities to our investors.”
          </p>
          <p className="font-body text-lg text-mx-gray-dark">Cathryn Chen</p>
          <p className="font-body text-lg text-mx-gray-dark">Founder</p>
        </motion.div>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden bg-black align-middle shadow-xl transition-all">
                  <iframe
                    className="aspect-video h-full w-full"
                    src={`https://www.youtube.com/embed/${videoId}?&autoplay=1`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </PartialContainer>
  )
}
