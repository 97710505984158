import * as React from 'react'
import { motion } from 'framer-motion'
import { PartialContainer, Button } from 'components'
import Image from 'next/image'
import letUsGuideImage from './let-us-guide.png'

export const LetUsGuideYouSection = () => {
  return (
    <div className="relative">
      <div className="hidden md:block">
        <Image
          src={letUsGuideImage}
          alt="Young Woman"
          className="max-h-[700px] min-h-[300px] w-full object-cover object-right-top"
        />
      </div>
      <div className="relative inset-0 flex w-full flex-col md:absolute md:flex-row">
        <PartialContainer className="flex w-full flex-col justify-center">
          <motion.h2
            initial={{
              opacity: 0,
              x: -40,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
            }}
            className="mb-0 max-w-3xl font-heading text-4xl md:mb-4 md:text-5xl md:text-white lg:text-6xl">
            Let us guide you on your investment journey
          </motion.h2>

          <div className="mt-8 lg:mt-16">
            <motion.div
              initial={{
                opacity: 0,
                x: -40,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.1,
              }}
              className="">
              <a
                rel="noreferrer"
                href={`${
                  process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL
                    ? process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL
                    : 'https://calendly.com/mx-jan'
                }`}
                target="_blank">
                <Button newButton className="w-fit">
                  Schedule a call
                </Button>
              </a>
            </motion.div>
          </div>
        </PartialContainer>
        <div className="mt-4 block md:hidden">
          <Image
            src={letUsGuideImage}
            alt="Young Woman"
            className="max-h-[700px] min-h-[300px] w-full object-cover object-right-top"
          />
        </div>
      </div>
    </div>
  )
}
